import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import "../style/components/Layout.css";

export default function Layout() {
  return (
    <>
      <Header />
      <div className="app-container">
        <Outlet />
      </div>
      <Footer />
    </>
  );
}
