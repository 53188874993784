import matruusi from "../assets/images/degrees/matruusi.png";
import peramies from "../assets/images/degrees/perämies.png";
import kapteeni from "../assets/images/degrees/kapteeni.png";
import amiraali from "../assets/images/degrees/amiraali.png";
import poiju from "../assets/images/degrees/poiju.png";
import jokikarhu from "../assets/images/degrees/jokikarhu.png";
import hinaaja from "../assets/images/degrees/hinaaja.png";
import ahtaaja from "../assets/images/degrees/ahtaaja.png";
import kommodori from "../assets/images/degrees/kommodori.png";
import "../style/pages/Degree.css";

export default function Degree() {
  return (
    <>
      <h1>TUTKINTO</h1>
      <p>
        Akateeminen Aurajokilaivuritutkinto suoritetaan kiertämällä
        tutkintokarttaan merkittyjä tutkintopaikkoja ja ostamalla niistä leimaan
        oikeuttavia juomia tai ruokia. Hyväksyttyyn tutkintoon tarvitaan alla
        olevien suoritusrajojen mukainen määrä <b>eri</b> leimoja kartassa.
        <br />
        <br />
        Huomaa, että jokaisesta tutkintopaikasta on mahdollista ostaa myös
        alkoholittomia juomia ja saada niistä leima.
        <br />
        <br />
        HUOM! Voit aloittaa jo ensimmäisellä suorituskerralla Kapteenista ja
        jatkaa siitä eteenpäin.
      </p>
      <h2>Suoritettavat tutkinnot</h2>
      <div className="base-degrees-container">
        <div className="degree-container">
          <p className="degree-title">Matruusi</p>
          <img src={matruusi} alt="Matruusimerkki" />
          <p className="degree-desc">6</p>
        </div>
        <div className="degree-container">
          <p className="degree-title">I Perämies</p>
          <img src={peramies} alt="Perämiesmerkki" />
          <p className="degree-desc">9</p>
        </div>
        <div className="degree-container">
          <p className="degree-title">Kapteeni</p>
          <img src={kapteeni} alt="Kapteenimerkki" />
          <p className="degree-desc">12</p>
        </div>
      </div>
      <h2>Jatkotutkinnot</h2>
      <div className="extra-degrees-container">
        <div className="degree-container">
          <p className="degree-title">Kommodori</p>
          <img src={kommodori} alt="Kommodorimerkki" />
          <p className="degree-desc">
            Kapteenin tasoisesta suorituksesta 1. Kapteenin jälkeen
          </p>
        </div>
        <div className="degree-container">
          <p className="degree-title">Amiraali</p>
          <img src={amiraali} alt="Amiraalimerkki" />
          <p className="degree-desc">
            Kapteenin tasoisesta suorituksesta 2. Kapteenin jälkeen
          </p>
        </div>
        <div className="extra-degrees-row-container">
          <div className="degree-container">
            <p className="degree-title">Poiju</p>
            <img src={poiju} alt="Poijumerkki" />
            <p className="degree-desc">
              Kapteenin tasoisesta suorituksesta Amiraalin jälkeen (ellei
              Jokikarhu)
            </p>
          </div>
          <div className="degree-container">
            <p className="degree-title">Jokikarhu</p>
            <img src={jokikarhu} alt="Jokikarhumerkki" />
            <p className="degree-desc">
              Kapteenin tasoisesta suorituksesta Amiraalin jälkeen (ellei Poiju)
            </p>
          </div>
        </div>
        <div className="degree-container">
          <p className="degree-title">Hinaaja</p>
          <img src={hinaaja} alt="Hinaajamerkki" />
          <p className="degree-desc">
            Kapteenin tasoisesta suorituksesta Poijun <b>ja</b> Jokikarhun
            jälkeen
          </p>
        </div>
        <div className="degree-container ahtaaja">
          <p className="degree-title">Ahtaaja</p>
          <img src={ahtaaja} alt="Ahtaajamerkki" />
          <p className="degree-desc">
            Kapteenin tasoisesta suorituksesta Hinaajan jälkeen
          </p>
        </div>
      </div>
    </>
  );
}
