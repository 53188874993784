import "../style/components/Footer.css";
import logo from "../assets/images/logos/new_logo_white_no_bg.png";
import instagram from "../assets/images/icons/instagram.png";

export default function Footer() {
  return (
    <footer>
      <img src={logo} alt="Akateeminen Aurajokilaivuriyhdistys ry" />
      <ul className="footer-socials">
        <li>
          <a
            href="https://www.instagram.com/aurajokilaivuritutkinto/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={instagram} alt="Instagram" />
            aurajokilaivuritutkinto
          </a>
        </li>
      </ul>
      <p>Akateeminen Aurajokilaivuriyhdistys ry © 2025</p>
    </footer>
  );
}
