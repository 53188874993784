import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import FAQ from "./pages/FAQ";
import Tickets from "./pages/Tickets";
// import Schedule from "./pages/Schedule";
import AAly from "./pages/AAly";
import Contact from "./pages/Contact";
import Degree from "./pages/Degree";
import IceBreaker from "./pages/IceBreaker";
import Home from "./pages/Home";
import Layout from "./components/Layout";
import NoPage from "./pages/NoPage";
// import Afterparty from "./pages/Afterparty";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="liput" element={<Tickets />} />
          {/* <Route path="ohjelma" element={<Schedule />} /> */}
          <Route path="tutkinto" element={<Degree />} />
          {/*<Route path="jatkot" element={<Afterparty />} />*/}
          <Route path="jaansarkijat" element={<IceBreaker />} />
          <Route path="aaly" element={<AAly />} />
          <Route path="ukk" element={<FAQ />} />
          <Route path="yhteystiedot" element={<Contact />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
