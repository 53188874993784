import { Link } from "react-router-dom";

export default function FAQ() {
  return (
    <>
      <h1>USEIN KYSYTYT KYSYMYKSET</h1>
      <h2>Kuinka monta lippua voin ostaa kerralla?</h2>
      <p>Kide.app lipunmyynnistä voit ostaa enintään 4 lippua kerralla.</p>
      <h2>Mitä lippu sisältää?</h2>
      <p>
        Lippuun sisältyy osallistuminen Akateemiseen Aurajokilaivuritutkintoon,
        johon kuuluu tapahtumamerkki, tutkintokartta sekä tutkintomerkki
        onnistuneesta suorituksesta. Jatkolliset liput sisältävät myös
        sisäänpääsyn valitsemaasi jatkopaikkaan sekä narikan.
      </p>
      <h2>Mitä jos hävitän tutkintokartan?</h2>
      <p>
        Vain oikein täytetyllä tutkintokartalla voit saada ansaitsemasi
        tutkintomerkin merkkienvaihdosta. Joten älä siis hävitä
        tutkintokarttaasi!
      </p>
      <h2>Montako leimaa tarvitsee kerätä?</h2>
      <p>
        Tutkintomerkkien suoritusrajat voit tarkistaa{" "}
        <Link to="/tutkinto">tutkinto-sivulta</Link>. Sieltä löydät myös muutkin
        ohjeet tutkintokartan oikeaoppiseen täyttämiseen.
      </p>
      <h2>Mikä ihmeen tuplatutkinto?</h2>
      <p>
        Mikäli haluat suorittaa useamman tutkinnon, voit lippua ostaessasi
        lisätä lipun hintaan toisen tutkintokartan lisäpalveluna! Sinun ei siis
        tarvitse ostaa kahta erillistä lippua. Tuplatutkintokarttoja saatavilla
        vain rajattu määrä. (HUOM! Toisen tutkintokartan tilaaminen EI sisällä
        toista tapahtumamerkkiä tai jatkorannekketta)
      </p>
    </>
  );
}
