export default function Contact() {
  return (
    <>
      <h1>YHTEYSTIEDOT</h1>
      <h2>Postiosoite:</h2>
      <p>
        Ylioppilastalo B 1. kerros
        <br />
        Rehtorinpellonkatu 4-6 B
        <br />
        20500 Turku
      </p>
      <h2>Sähköposti:</h2>
      <p>
        <a href="mailto:hallitus@aatu.fi" target={"_blank"} rel="noreferrer">
          hallitus@aatu.fi
        </a>
      </p>
      <h2>Y-tunnus:</h2>
      <p>1481154-1</p>
      <h2>Ravintoloille:</h2>
      <p>
        Haluatko ravintolasi mukaan AATUun tutkintopaikkana? Ota yhteyttä
        suoraan tutkintopaikkavastaavaamme sähköpostitse <br />
        <a
          href="mailto:tutkintopaikat@aatu.fi"
          target={"_blank"}
          rel="noreferrer"
        >
          tutkintopaikat@aatu.fi
        </a>
      </p>
      <h2>Yhteistyöhon?</h2>
      <p>
        Sponsorointi ja muissa yhteistyötarjouksissa voit olla yhteydessä
        ulkosuhdevastaavamme <br />
        <a href="mailto:ulkosuhteet@aatu.fi" target={"_blank"} rel="noreferrer">
          ulkosuhteet@aatu.fi
        </a>
      </p>
    </>
  );
}
