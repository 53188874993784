export default function IceBreaker() {
  return (
    <>
      <h1>Jäänsärkijäbileet</h1>
      <p>
        AAly järjestää perinteiset Jäänsärkijäbileet maaliskuussa Turun
        Vegasissa!
      </p>
      <p>
        Jäänsärkijäbileistä voit noutaa oman lippusi Akateemiseen
        Aurajokilaivuritutkintoon ennakkoon alennettuun hintaan sekä tietenkin
        juhlia kesän lähestymistä! Ole ajoissa paikalla, sillä ennakkolippuja on
        tarjolla vain rajallinen määrä.
      </p>
      <p>
        Juhlavuoden kunniaksi Jäänsärkijöissä tänä vuonna jotain spesiaalimpaa
        ohjelmaa luvassa. Kannattaa siis tulla paikalle!
      </p>
      <p>Jäänsärkijäbileistä saatavilla myös upea Jäänsärkijämerkki!</p>
      <h2>Ankkapong</h2>
      <p>
        Ennen Jäänsärkijöitä voit osallistua myös AAlyn ja{" "}
        <a href="https://www.beerpongturku.fi/">Beer Pong Turun</a>{" "}
        yhteisturnaukseen AnkkaPongiin! AnkkaPongin pääpalkintona tarjolla mm.
        AATU-lippuja sekä yhteistyökumppanimme tuotteita. AnkkaPongin
        erikoisuutena on, että turnaukseen ilmoittaudutaan yksin ja joukkueet
        arvotaan turnauksen aluksi.
      </p>
      <p>AnkkaPongin lippuja varten seuraa Beer Pong Turun tiedotusta!</p>
    </>
  );
}
