export default function Tickets() {
  return (
    <>
      <h1>LIPUT</h1>
      <h2>NETTILIPUT</h2>
      <p>
        AATU-liput myydään{" "}
        <a href="https://kide.app/">Kide.appissa keväällä 2025</a>!
      </p>
      <p>Lisätietoja tulossa myöhemmin</p>
      <h2>RYHMÄTILAUKSET</h2>
      <p>
        Ryhmätilaukset vuoden 2025 AATUn ovat nyt auki! Opiskelijajärjestösi
        ryhmätilausta varten voit laittaa sähköpostia osoitteeseen
        <br />
        <a href="mailto:liput@aatu.fi">liput@aatu.fi</a>
      </p>
    </>
  );
}
